.event-content-custom {
  color: var(--custom-font-color) !important;
}


/* Adding hover effect on each cell */
.fc-daygrid-day:hover .event-content[style*="display: none"] {
  display: inline-block !important;
}

/* Emphasize the calendar dates by moving it at the center and giving it a few space */
.fc .fc-daygrid-day-number {
  display: flex;
  justify-content: center;
  height: 20px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.2em;
  z-index: 10;
  margin-top: 5px;
  color: red !important;
}

.fc-daygrid-day-top {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
}

.fc-daygrid-day-frame {
  position: relative;
  padding-top: 30px;
}




/* Override FullCalendar header styles */
.fc .fc-toolbar-title, 
.fc .fc-toolbar button {
  color: #ffff !important;
}

/* Override FullCalendar day numbers styles */
.fc .fc-daygrid-day-number {
  color: #a2a9b2 !important;
}

/* Calendar Day(header) Color */
a {
  color: #a2a9b2;
  text-decoration: none;
  background-color: transparent;
} 

/* Update calendar color - end */

/* Mobile View CSS */
@media (max-width: 768px) {
  .mobile-padding-reset {
    padding-left: 0 !important;
  }
}
/* Mobile View CSS */

/* Import Montserrat font */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

/* Apply Montserrat font to the body */
body {
    font-family: "Montserrat", sans-serif;
    z-index: 0 !important;
}

/* Adjust margin-top for the custom container */
.custom-container {
    margin-top: 5rem !important;
}

/* Styles for the calendar container */
#calendar {
    max-width: 100%;
    margin: auto;
    background: #fff;
    padding: 15px;
}

/* Styles for calendar events */
.fc-event {
    border: 1px solid #eee !important;
}

.fc-content {
    padding: 3px !important;
}

.fc-content .fc-title {
    display: block !important;
    overflow: hidden;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
}

/* Styles for custom button in calendar */
.fc-customButton-button {
    font-size: 13px !important;
    position: absolute;
    top: 5rem;
    left: 50%;
    transform: translateY(-50%);
}

/* Styles for form groups */
.form-group {
    margin-bottom: 1rem;
}

.form-group>label {
    margin-bottom: 10px;
}

/* Styles for delete modal buttons */
#delete-modal .modal-footer > .btn {
    border-radius: 3px !important; 
    padding: 0px 8px !important;
    font-size: 15px;
}

/* Styles to hide overflow-y in the scroller */
.fc-scroller {
    overflow-y: hidden !important;
}

/* Styles for the context menu */
.context-menu {
    position: absolute;
    z-index: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
    padding: 5px;
}

.context-menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.context-menu ul>li {
    display: block;
    padding: 5px 15px;
    list-style-type: none;
    color: #333;
    display: block;
    cursor: pointer;
    margin: 0 auto;
    transition: 0.10s;
    font-size: 13px;
}

.context-menu ul>li:hover {
    color: #fff;
    background-color: #007bff;
    border-radius: 2px;
}

/* Styles for font-awesome icons */
.fa,
.fas {
    font-size: 13px;
    margin-right: 4px;
}
