.sidenav {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  padding-top: 0px;
  padding-left: 1rem;
  background-image: url('../../assets/icons/sidenav-bg.png');
  background-size: cover; 
  background-color: #6941c6;
  color: white;
  transition: width 0.3s ease;

  &.collapsed {
    width: 75px; 
  }

  .brand-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;

    .brand-logo {
      width: 40px;
      height: 35px;
      margin-right: 10px;
      margin-bottom: 15px;
    }

    .brand-name {
      font-size: 20px;
      font-weight: bold;
      color: white;
      margin-bottom: 15px;
    }

    .collapse-icon {
      font-size: 20px;
      transition: transform 0.3s ease;
      margin-left: auto; 
      margin-top: 5rem;

      &:hover {
        animation: glow 1s infinite; 
        color: #d650d6;
        outline: none; 
      }
      &:focus {
        outline: none; 
      }
    }

    &.collapsed {
      .collapse-icon {
        transform: rotate(180deg);
      }
    }
  }

  a {
    padding: 5px;
    font-size: 15px;
    color: white;
    display: block;
    text-decoration: none;

    &:hover {
      background-color: #7e56d8;
      color: white;
      width: 250px;
    }
  }
}

@media (max-width: 767px) {
  .sidenav {
    display: none; /* Hide on mobile view */
  }
}

img {
  vertical-align: middle;
  border-style: none;
}

/* Font color of card header - start */
.card h1,
.card h2,
.card h3,
.card h4,
.card h5,
.card h6, {
  color: #000;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  color: #000;
}
/* Font color of card header - end */
