.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.5rem 0.7rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.42857;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.form-control:focus {
  color: rgba(255, 255, 255, 0.8);
  background-color: #ffffff;
  border-color: rgba(50, 151, 211, 0.25);
  outline: 0;
}

/* Form controls */
/*--------Agent Schedule Form --------------*/

.form-control {
  border-color: #cbcbcb;
  color: black !important;
  border-radius: 0.4285rem;
  font-size: 0.75rem;
  -webkit-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  -ms-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
}



.form-control:focus {
  border-color: #e14eca;
  background-color: transparent;
  box-shadow: none;
}


/*--------Agent Schedule Form --------------*/

.modal-content .modal-header {
  border-bottom: none;
}

.modal-content .modal-footer {
  border-top: 0;
}

.btn:not([data-action]):hover,
.navbar .navbar-nav > a.btn:not([data-action]):hover {
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  transform: translateY(-1px);
  -webkit-transform: translateY(-1px);
}

.ml-auto,
.mx-auto {
  margin-left: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(34, 42, 66, 0.2);
  border-radius: 0.2857rem;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.5);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #222a42;
}

.modal-backdrop.show {
  opacity: 0.16;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24px 24px 0 24px;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: calc(0.2857rem - 1px);
  border-top-right-radius: calc(0.2857rem - 1px);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 24px 24px 16px 24px;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 24px 16px 24px-0.25rem;
  border-top: 1px solid #e9ecef;
  border-bottom-right-radius: calc(0.2857rem - 1px);
  border-bottom-left-radius: calc(0.2857rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-content {
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.2),
      0 5px 15px rgba(0, 0, 0, 0.17);
  }
  .modal-sm {
    max-width: 380px;
  }
}

.mb-5,
.my-5 {
  margin-bottom: 3rem;
}

.d-flex {
  display: flex;
} 


