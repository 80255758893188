.rbc-event-label {
    font-size: 70%;
}

div.rbc-event:hover {
    min-height: 15%;
    /* min-height: 20%; */
}

div.rbc-row {
    min-height: 50px !important;
    align-items: center;
}

div.rbc-header {
    padding-bottom: 3px !important;
}

div.rbc-row {
    min-height: 45px !important;
}

button.rbc-button-link > span {
    display: none;
}