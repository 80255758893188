
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(34, 42, 66, 0.05);
}

.table-hover tbody tr:hover {
  color: #525f7f;
  background-color: rgba(34, 42, 66, 0.075);
}

.card .card-header {
  padding: 15px 15px 0;
  border: 0;
  color: rgba(255, 255, 255, 0.8);
}

