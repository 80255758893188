.datepicker-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

label {
  margin-right: 10px;
}

.custom-datepicker-input {
  position: relative;
}

.custom-input {
  display: flex;
  align-items: center;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.calendar-icon {
  margin-left: 8px;
  margin-right: 8px;
}



.daterange-container {
  display: flex;
}

.datepicker-wrapper {
  margin-right: 20px;
}







.card .card-footer {
  background-color: transparent;
  border: 0;
  padding: 15px;
}

.btn + .btn {
  margin-left: 5px;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.table th,
.table td {
  padding: 1rem;
  vertical-align: top;
  border-top: 0.0625rem solid #e3e3e3;
}

.btn.dropdown-toggle:after,
.navbar .navbar-nav > a.btn.dropdown-toggle:after {
  margin-left: 30px !important;
}

.btn {
  cursor: pointer;
}

.btn.dropdown-toggle[data-toggle="dropdown"] {
  padding: 10px;
  margin: 0;
  margin-bottom: 5px;
}

.btn.dropdown-toggle[data-toggle="dropdown"]:after {
  content: "";
  margin-left: 5px;
}

.dropdown-toggle:after {
  content: unset;
}


