
/* zebra sheme */
.table th,
.table td {
  text-align: center;
  vertical-align: middle;
}

.table thead tr {
  background-color: #f2f2f2;
}




/* table hover effect */
.table tbody tr:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}


.justify-content-between {
  justify-content: space-between;
}


#agents-table-css {
  background-color: white;
}



.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}


.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none;
}

.page-link {
  position: relative;
  display: block;
  padding: 0 0.6875rem;
  margin-left: -0.0625rem;
  line-height: 1.25;
  color: #a7a7a7;
  background-color: transparent;
  border: 0.0625rem solid #e3e3e3;
}
.page-link:hover {
  z-index: 2;
  color: black;
  text-decoration: none;
  background-color: #e3e3e3;
  border-color: #e3e3e3;
}

.table > thead > tr > th {
  border-bottom-width: 1px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  border: 0;
  color: #a7a7a7;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 12px 7px;
  vertical-align: middle;
}
/* Agent Database CSS */

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 0.0625rem solid rgba(34, 42, 66, 0.05);
  border-radius: 0.2857rem;
}

.card {
  /*background: #27293d;*/
  border: 0;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.1);
}

.card .card-body {
  padding: 15px;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.5rem;
}

.table-bordered {
  border: 0.0625rem solid #e3e3e3;
}

table {
  border-collapse: collapse;
}

h3,
.h3 {
  font-size: 1.4375rem;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #525f7f;
  background-color: transparent;
}

.table-bordered th,
.table-bordered td {
  border: 0.0625rem solid #e3e3e3;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

