.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-image: url('https://media0.giphy.com/media/v1.Y2lkPTc5MGI3NjExNXhwZzB2ZHNzMTlxcXMxaDJreXh3amd1NWd2dzV2YXM1ODR0OWl2bSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/WPwD0gdWFsNRy8LA2P/giphy.gif');*/
  background: linear-gradient(#141e30, #243b55);
  background-size: cover;
  background-position: center;
  z-index: 2000;
}


:root {
  --main-color: #e102ff;
  --second-color: #faebd7;
}


/* Position for loader span elements */
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}

.m {
  font-size: 24px;
  margin: 4px;
}


/* Keyframes for the loading animation */
@keyframes loading {
  to {
    text-shadow: 20px 0 70px var(--main-color);
    color: var(--main-color);
  }
}

/* Styling for loader span elements */
.loader span {
  color: var(--second-color);
  text-shadow: 0 0 0 var(--second-color);
  animation: loading 1s ease-in-out infinite alternate;
}

/* Styling for loader span elements with specific delays */
.loader span:nth-child(2) {
  animation-delay: 0.1s;
}
.loader span:nth-child(3) {
  animation-delay: 0.2s;
}

/* Styling for loader span elements with specific delays */
.loader span:nth-child(2) {
  -webkit-animation-delay: 0.1s;
}
.loader span:nth-child(3) {
  -webkit-animation-delay: 0.2s;
}
.loader span:nth-child(4) {
  -webkit-animation-delay: 0.3s;
}
.loader span:nth-child(5) {
  -webkit-animation-delay: 0.4s;
}
.loader span:nth-child(6) {
  -webkit-animation-delay: 0.5s;
}
.loader span:nth-child(7) {
  -webkit-animation-delay: 0.6s;
}
.loader span:nth-child(8) {
  -webkit-animation-delay: 0.7s;
}
.loader span:nth-child(9) {
  -webkit-animation-delay: 0.8s;
}
.loader span:nth-child(10) {
  -webkit-animation-delay: 0.9s;
}
.loader span:nth-child(11) {
  -webkit-animation-delay: 1s;
}
.loader span:nth-child(12) {
  -webkit-animation-delay: 1.1s;
}
.loader span:nth-child(13) {
  -webkit-animation-delay: 1.2s;
}
.loader span:nth-child(14) {
  -webkit-animation-delay: 1.3s;
}
.loader span:nth-child(15) {
  -webkit-animation-delay: 1.4s;
}
.loader span:nth-child(16) {
  -webkit-animation-delay: 1.5s;
}
.loader span:nth-child(17) {
  -webkit-animation-delay: 1.6s;
}
.loader span:nth-child(18) {
  -webkit-animation-delay: 1.7s;
}
.loader span:nth-child(19) {
  -webkit-animation-delay: 1.8s;
}
.loader span:nth-child(20) {
  -webkit-animation-delay: 1.9s;
}
.loader span:nth-child(21) {
  -webkit-animation-delay: 2s;
}
.loader span:nth-child(22) {
  -webkit-animation-delay: 2.1s;
}
.loader span:nth-child(23) {
  -webkit-animation-delay: 2.2s;
}
.loader span:nth-child(24) {
  -webkit-animation-delay: 2.3s;
}
.loader span:nth-child(25) {
  -webkit-animation-delay: 2.4s;
}
.loader span:nth-child(26) {
  -webkit-animation-delay: 2.5s;
}
.loader span:nth-child(27) {
  -webkit-animation-delay: 2.6s;
}
.loader span:nth-child(28) {
  -webkit-animation-delay: 2.7s;
}
.loader span:nth-child(29) {
  -webkit-animation-delay: 2.8s;
}
.loader span:nth-child(30) {
  -webkit-animation-delay: 2.9s;
}
.loader span:nth-child(31) {
  -webkit-animation-delay: 3.0s;
}
.loader span:nth-child(32) {
  -webkit-animation-delay: 3.1s;
}
.loader span:nth-child(33) {
  -webkit-animation-delay: 3.2s;
}
.loader span:nth-child(34) {
  -webkit-animation-delay: 3.3s;
}
.loader span:nth-child(35) {
  -webkit-animation-delay: 3.4s;
}
.loader span:nth-child(36) {
  -webkit-animation-delay: 3.5s;
}
.loader span:nth-child(37) {
  -webkit-animation-delay: 3.6s;
}
.loader span:nth-child(38) {
  -webkit-animation-delay: 3.7s;
}
.loader span:nth-child(39) {
  -webkit-animation-delay: 3.8s;
}
.loader span:nth-child(40) {
  -webkit-animation-delay: 3.9s;
}
.loader span:nth-child(41) {
  -webkit-animation-delay: 4.0s;
}
.loader span:nth-child(42) {
  -webkit-animation-delay: 4.1s;
}
.loader span:nth-child(43) {
  -webkit-animation-delay: 4.2s;
}
.loader span:nth-child(44) {
  -webkit-animation-delay: 4.3s;
}