.retainer-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.retainer-modal-content {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #888;
  width: 40%;
  position: fixed;
  z-index: 1000;
}

.retainer-close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #aaa;
  font-size: 20px;
  cursor: pointer;
}


.bg-white {
  background-color: #ffffff;
}

.retainer-icon-hover-glow:hover {
  box-shadow: 0 0 20px #df00ff;
}