/* For color of 'Diff' */

.diff-positive {
  color: green;
}

.diff-negative {
  color: red;
}



/* Card icon alignment - start */

.card-header-content {
  display: flex;
  align-items: center;
}

.card-icon {
  margin-right: 10px;
}

.card-category {
  margin: 0;
  font-size: 1rem;
}

.card-value {
  margin: 0;
  font-size: 1.5rem;
}


/* Card icon alignment - end */


/*---- Blinking Card Animation Start --------*/

.card-with-box-shadow {
  transition: box-shadow 0.3s ease-in-out;
}

.card-with-box-shadow:hover {
  animation: border-color-animation 1s linear infinite;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

@keyframes border-color-animation {
  0% {
    box-shadow: 0 0 5px 0 rgba(255, 0, 0, 0.5), 0 0 10px 0 rgba(255, 0, 0, 0.5);
  }
  25% {
    box-shadow: 0 0 5px 0 rgba(0, 255, 0, 0.5), 0 0 10px 0 rgba(105, 65, 198, 1);
  }
  50% {
    box-shadow: 0 0 5px 0 rgba(0, 0, 255, 0.5), 0 0 10px 0 rgba(225, 78, 202, 1);
  }
  75% {
    box-shadow: 0 0 5px 0 rgba(255, 255, 0, 0.5), 0 0 10px 0 rgba(105, 65, 198, 1);
  }
  100% {
    box-shadow: 0 0 5px 0 rgba(255, 0, 0, 0.5), 0 0 10px 0 rgba(225, 78, 202, 1);
  }
}



/*---- Blinking Card Animation End --------*/


@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

.card .card-header .card-category {
  color: #9a9a9a;
  margin-bottom: 5px;
  font-weight: 300;
}

