.date-picker-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.date-picker-icon {
  margin-left: 10px;
}

.date-picker-icon-text {
  margin-left: 5px; 
}

.date-picker-row {
  display: flex;
  justify-content: flex-end;

}




.CardContainer {
  max-width: 800px;
  margin: 20px auto;
}

.CardWithShadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.PaginationContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.PaginationContainer .page-link {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.PaginationContainer .page-link:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.CardFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}







