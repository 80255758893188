* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #525f7f;
  text-align: left;
  background-color: whitesmoke;
  overflow-x: hidden;
  border-top: 23px solid #6941c6;
  border-top-left-radius: 50px;
  
}